import { useState, useEffect, useRef, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { isMobile } from "react-device-detect";
import gsap from "gsap";
import Layout from "./components/Layout.js";
import Experience from "./components/Experience.js";
import ExperienceSingleHeart from "./components/ExperienceSingleHeart.js";
import ExperienceSingleCompass from "./components/ExperienceSingleCompass.js";
import Intro from "./components/Intro.js";
import Recommendations from "./components/Recommendations.js";
import audioBgFile from "./media/audio/audio-bg.mp3";

export default function App() {
  const app = useRef();
  const canvasEl = useRef();
  const audioBg = useRef(new Audio(audioBgFile));

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [isIntroVideoScreen, setIsIntroVideoScreen] = useState(false);
  const [isStartExperience, setIsStartExperience] = useState(false);
  const [isMainScreen, setIsMainScreen] = useState(false);
  const [isSingleModelPage, setIsSingleModelPage] = useState(false);
  const [isExplorePage, setIsExplorePage] = useState(false);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const [isRecommendationsScreen, setIsRecommendationsScreen] = useState(false);
  const [isModelLinkOn, setIsModelLinkOn] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isCursorLabelVisible, setIsCursorLabelVisible] = useState(false);
  const [appBg, setAppBg] = useState({
    backgroundImage: `url(/home-js/textures/main-bg.jpg)`,
  });
  const [boxData, setBoxData] = useState({
    pageTitle: "",
    pageBg: "",
    pageColorSheme: "",
    buttonLink: "",
    boxes: [],
  });

  useEffect(() => {
    isMuted ? (audioBg.current.muted = true) : (audioBg.current.muted = false);
  }, [isMuted]);

  const playBgAudio = () => {
    audioBg.current.loop = true;
    audioBg.current.play();
    //audioBg.current.volume = 0;

    gsap.fromTo(
      audioBg.current,
      {
        volume: 0,
      },
      {
        volume: 1,
        duration: 5,
      }
    );
  };

  const toggleAppBg = () => {
    isRecommendationsScreen
      ? setAppBg({
          backgroundImage: `url(${boxData.pageBg})`,
        })
      : setAppBg({
          backgroundImage: `url(/home-js/textures/main-bg.jpg)`,
        });
  };

  useEffect(() => {
    if (isInitialLoadDone) {
      isRecommendationsScreen ? mainOutAnimation() : mainInAnimation();
    }
  }, [isRecommendationsScreen]);

  const setStatesOnStartExperienceEnd = () => {
    setIsInitialLoadDone(true);
    setIsModelLinkOn(true);
    playBgAudio();
  };

  useEffect(() => {
    if (isStartExperience) {
      setTimeout(setStatesOnStartExperienceEnd, 3000);
    }
  }, [isStartExperience]);

  const mainOutAnimation = () => {
    const canvasTopParent = canvasEl.current.parentNode.parentNode;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    gsap.to(canvasTopParent, {
      alpha: 0,
      duration: 2,
      ease: "power2.inOut",
    });
    gsap.to(canvasTopParent, {
      delay: 2,
      display: "none",
    });
  };

  const mainInAnimation = () => {
    const canvasTopParent = canvasEl.current.parentNode.parentNode;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    gsap.to(canvasTopParent, {
      //delay: 2,
      display: "block",
    });
    gsap.to(canvasTopParent, {
      alpha: 1,
      duration: 2,
      ease: "power2.inOut",
    });
  };

  const handleVideoEnd = (duration = 3) => {
    gsap.to(".intro", {
      filter: "blur(100px)",
      alpha: 0,
      duration: duration,
      ease: "power2.inOut",
    });
    gsap.to(".intro", {
      display: "none",
      delay: 3,
    });

    setIsPlaying(false);

    const setStatesOnVideoEnded = () => {
      setIsIntroVideoScreen(false);
      setIsMainScreen(true);
    };

    setTimeout(setStatesOnVideoEnded, duration);
  };

  return (
    <>
      <Routes>
        <Route
          element={
            <Layout
              app={app}
              appBg={appBg}
              isNavOpen={isNavOpen}
              setIsNavOpen={setIsNavOpen}
              isInitScreen={isInitScreen}
              isIntroVideoScreen={isIntroVideoScreen}
              isStartExperience={isStartExperience}
              setIsStartExperience={setIsStartExperience}
              isMainScreen={isMainScreen}
              setIsMainScreen={setIsMainScreen}
              isInitialLoadDone={isInitialLoadDone}
              isSingleModelPage={isSingleModelPage}
              isRecommendationsScreen={isRecommendationsScreen}
              setIsRecommendationsScreen={setIsRecommendationsScreen}
              boxData={boxData}
              setBoxData={setBoxData}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              handleVideoEnd={handleVideoEnd}
              setIsModelLinkOn={setIsModelLinkOn}
              isCursorLabelVisible={isCursorLabelVisible}
              setIsCursorLabelVisible={setIsCursorLabelVisible}
              isExplorePage={isExplorePage}
            />
          }
        >
          <Route
            index
            element={
              <>
                <Intro
                  setIsInitScreen={setIsInitScreen}
                  setIsIntroVideoScreen={setIsIntroVideoScreen}
                  setIsMainScreen={setIsMainScreen}
                  isMuted={isMuted}
                  setIsPlaying={setIsPlaying}
                  isPlaying={isPlaying}
                  handleVideoEnd={handleVideoEnd}
                />
                <Canvas
                  ref={canvasEl}
                  shadows
                  camera={{
                    near: 1,
                    far: 200,
                    zoom: 130,
                  }}
                  orthographic
                >
                  <Experience
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    isStartExperience={isStartExperience}
                    setIsStartExperience={setIsStartExperience}
                    isMainScreen={isMainScreen}
                    isRecommendationsScreen={isRecommendationsScreen}
                    setIsRecommendationsScreen={setIsRecommendationsScreen}
                    isInitialLoadDone={isInitialLoadDone}
                    setIsInitialLoadDone={setIsInitialLoadDone}
                    isModelLinkOn={isModelLinkOn}
                    setIsCursorLabelVisible={setIsCursorLabelVisible}
                  />
                </Canvas>
                <Recommendations
                  isRecommendationsScreen={isRecommendationsScreen}
                  setIsRecommendationsScreen={setIsRecommendationsScreen}
                  boxData={boxData}
                />
              </>
            }
          />
          <Route
            path="/explore/"
            element={
              <>
                <Canvas
                  ref={canvasEl}
                  shadows
                  camera={{
                    near: 1,
                    far: 200,
                    zoom: 130,
                  }}
                  orthographic
                >
                  <Experience
                    canvasEl={canvasEl}
                    setIsInitScreen={setIsInitScreen}
                    isExplorePage={true}
                    setIsExplorePage={setIsExplorePage}
                    handleVideoEnd={handleVideoEnd}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    isStartExperience={isStartExperience}
                    setIsStartExperience={setIsStartExperience}
                    isMainScreen={isMainScreen}
                    setIsMainScreen={setIsMainScreen}
                    isRecommendationsScreen={isRecommendationsScreen}
                    setIsRecommendationsScreen={setIsRecommendationsScreen}
                    isInitialLoadDone={isInitialLoadDone}
                    setIsInitialLoadDone={setIsInitialLoadDone}
                    isModelLinkOn={isModelLinkOn}
                    setIsCursorLabelVisible={setIsCursorLabelVisible}
                  />
                </Canvas>
                <Recommendations
                  isRecommendationsScreen={isRecommendationsScreen}
                  setIsRecommendationsScreen={setIsRecommendationsScreen}
                  boxData={boxData}
                />
              </>
            }
          />
          <Route
            path="/uk-events-industry"
            element={
              <Canvas
                ref={canvasEl}
                shadows
                camera={{
                  near: 1,
                  far: 200,
                  zoom: 130,
                }}
                orthographic
              >
                <Suspense fallback={null}>
                  <ExperienceSingleHeart
                    isPlaying={isPlaying}
                    setIsInitScreen={setIsInitScreen}
                    setIsIntroVideoScreen={setIsIntroVideoScreen}
                    setIsMainScreen={setIsMainScreen}
                    isMainScreen={isMainScreen}
                    isSingleModelPage={isSingleModelPage}
                    setIsSingleModelPage={setIsSingleModelPage}
                    isRecommendationsScreen={isRecommendationsScreen}
                    setIsRecommendationsScreen={setIsRecommendationsScreen}
                    isInitialLoadDone={isInitialLoadDone}
                    setIsInitialLoadDone={setIsInitialLoadDone}
                    isModelLinkOn={isModelLinkOn}
                    setIsModelLinkOn={setIsModelLinkOn}
                    canvasEl={canvasEl}
                  />
                </Suspense>
              </Canvas>
            }
          />
          <Route
            path="/industry-community"
            element={
              <Canvas
                ref={canvasEl}
                shadows
                camera={{
                  near: 1,
                  far: 200,
                  zoom: 130,
                }}
                orthographic
              >
                <Suspense fallback={null}>
                  <ExperienceSingleCompass
                    isPlaying={isPlaying}
                    setIsInitScreen={setIsInitScreen}
                    setIsIntroVideoScreen={setIsIntroVideoScreen}
                    setIsMainScreen={setIsMainScreen}
                    isMainScreen={isMainScreen}
                    isSingleModelPage={isSingleModelPage}
                    setIsSingleModelPage={setIsSingleModelPage}
                    isRecommendationsScreen={isRecommendationsScreen}
                    setIsRecommendationsScreen={setIsRecommendationsScreen}
                    isInitialLoadDone={isInitialLoadDone}
                    setIsInitialLoadDone={setIsInitialLoadDone}
                    isModelLinkOn={isModelLinkOn}
                    setIsModelLinkOn={setIsModelLinkOn}
                    canvasEl={canvasEl}
                  />
                </Suspense>
              </Canvas>
            }
          />
        </Route>
      </Routes>
      {isMobile && (
        <div className="rotate-prompt">
          <h2>Please rotate your device to portrait view.</h2>
        </div>
      )}
    </>
  );
}
