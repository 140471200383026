import { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import Box from "./Box";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Recommendations({ boxData, isRecommendationsScreen }) {
  const [isSliderArrowsVisible, setSliderArrowsVisible] = useState(false);
  const [counter, setCounter] = useState(0);

  const carousel = useRef();
  const rewindSlider = () => {
    carousel.current.goToSlide(0, true);
  };
  const hideSliderArrows = () => {
    setSliderArrowsVisible(false);
  };

  const showSliderArrows = () => {
    setSliderArrowsVisible(true);
  };

  useEffect(() => {
    gsap.to(".recommendations", {
      visibility: "visible",
      delay: 2,
      duration: 1,
    });
  }, []);

  useEffect(() => {
    gsap.to(".recommendations__boxes .box", {
      pointerEvents: "none",
    });
    isRecommendationsScreen ? boxesInAnimation() : boxesOutAnimation();
    !isRecommendationsScreen && setTimeout(rewindSlider, 1500);
    !isRecommendationsScreen
      ? setTimeout(hideSliderArrows, 200)
      : setTimeout(showSliderArrows, 1000);
    setCounter(counter + 1);
  }, [isRecommendationsScreen]);

  const addPointerEvents = () => {
    gsap.to(".recommendations__boxes .box", {
      delay: 1.5,
      pointerEvents: "auto",
    });
  };

  const boxesInAnimation = () => {
    gsap.to(".recommendations", {
      visibility: "visible",
    });
    gsap.fromTo(
      ".recommendations__boxes .box",
      {
        y: -150,
        alpha: 0,
        filter: "blur(10px)",
      },
      {
        delay: 1,
        y: 0,
        alpha: 1,
        filter: "blur(0px)",
        stagger: {
          from: "center",
          each: 0.06,
        },
        onComplete: addPointerEvents(),
      }
    );
  };

  const boxesOutAnimation = () => {
    gsap.to(".recommendations__boxes .box", {
      delay: 0,
      y: -150,
      alpha: 0,
      filter: "blur(10px)",
      stagger: {
        from: "center",
        each: 0.06,
      },
    });
    gsap.to(".recommendations", {
      delay: 1.5,
      visibility: "hidden",
    });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1280, min: 800 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  const { pageTitle, pageColorSheme, boxes } = boxData;
  return (
    <div className="recommendations">
      <div className="container container--full">
        <h1
          className="recommendations__title"
          style={{ color: pageColorSheme === "light" ? "#fff" : "#000" }}
        >
          Our Recommendations For You
        </h1>

        <Carousel
          ref={carousel}
          containerClass="recommendations__slider"
          sliderClass="recommendations__boxes"
          itemClass="recommendations__box-wrapper"
          responsive={responsive}
          draggable={false}
          arrows={isSliderArrowsVisible}
          centerMode={true}
          infinite={true}
          refreshSlider={boxes}
          keyBoardControl={true}
        >
          {boxes.map((box, index) => (
            <Box title={box.title} boxData={box} key={index} />
          ))}
        </Carousel>

        <h2 className="recommendations__button">{pageTitle}</h2>
      </div>
    </div>
  );
}
