import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Cursor from "./Cursor";

export default function Layout({
  app,
  appBg,
  isNavOpen,
  setIsNavOpen,
  isInitScreen,
  isIntroVideoScreen,
  isStartExperience,
  setIsStartExperience,
  isMainScreen,
  setIsMainScreen,
  isInitialLoadDone,
  isSingleModelPage,
  isRecommendationsScreen,
  setIsRecommendationsScreen,
  boxData,
  setBoxData,
  isMuted,
  setIsMuted,
  isPlaying,
  setIsPlaying,
  handleVideoEnd,
  setIsModelLinkOn,
  isCursorLabelVisible,
  setIsCursorLabelVisible,
  isExplorePage,
}) {
  return (
    <>
      <div
        ref={app}
        className={`app ${isNavOpen ? "nav-opened" : ""}`}
        style={appBg}
      >
        <Header
          isInitScreen={isInitScreen}
          isIntroVideoScreen={isIntroVideoScreen}
          isMainScreen={isMainScreen}
          isSingleModelPage={isSingleModelPage}
          isNavOpen={isNavOpen}
          setIsNavOpen={setIsNavOpen}
          isRecommendationsScreen={isRecommendationsScreen}
          boxData={boxData}
          setIsModelLinkOn={setIsModelLinkOn}
        />
        <Outlet />
        <Footer
          isInitScreen={isInitScreen}
          isIntroVideoScreen={isIntroVideoScreen}
          isStartExperience={isStartExperience}
          setIsStartExperience={setIsStartExperience}
          isMainScreen={isMainScreen}
          setIsMainScreen={setIsMainScreen}
          isInitialLoadDone={isInitialLoadDone}
          isSingleModelPage={isSingleModelPage}
          isNavOpen={isNavOpen}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          handleVideoEnd={handleVideoEnd}
          isRecommendationsScreen={isRecommendationsScreen}
          setIsRecommendationsScreen={setIsRecommendationsScreen}
          setBoxData={setBoxData}
          setIsModelLinkOn={setIsModelLinkOn}
          isExplorePage={isExplorePage}
        />
        <Cursor
          app={app}
          isCursorLabelVisible={isCursorLabelVisible}
          setIsCursorLabelVisible={setIsCursorLabelVisible}
          isMainScreen={isMainScreen}
        />
      </div>
    </>
  );
}
