import { useRef, useEffect } from "react";
import gsap from "gsap";
import Menu from "./Menu";
import { ReactComponent as Logo } from "../media/PowerOfEventsLogo.svg";
import { ReactComponent as LogoAlt } from "../media/PowerOfEventsLogoAlt.svg";

export default function Header({
  isInitScreen,
  isIntroVideoScreen,
  isMainScreen,
  isSingleModelPage,
  isNavOpen,
  setIsNavOpen,
  isRecommendationsScreen,
  boxData,
  setIsModelLinkOn,
}) {
  const logoImg = useRef();
  const menuIcon = useRef();

  useEffect(() => {
    isInitScreen && headerAnimation();
    isMainScreen && !isSingleModelPage && logoAnimation();
    isSingleModelPage && logoAnimation();
  }, [isInitScreen, isMainScreen, isSingleModelPage]);

  const headerAnimation = () => {
    gsap.from(menuIcon.current, {
      duration: 1,
      alpha: 0,
      y: -100,
    });
  };

  const logoAnimation = () => {
    gsap.fromTo(
      logoImg.current,
      {
        alpha: 0,
        y: -100,
      },
      {
        duration: 1,
        alpha: 1,
        y: 0,
      }
    );
  };

  const { pageColorSheme } = boxData;
  return (
    <header className="header">
      <div className="container container--header">
        <div className="header__content">
          <a
            ref={logoImg}
            href="https://www.thepowerofevents.org/"
            className="header__logo-wrapper"
          >
            {pageColorSheme === "light" && isRecommendationsScreen ? (
              <LogoAlt
                className={`header__logo ${
                  isIntroVideoScreen ? "header__logo--hidden" : ""
                }`}
              />
            ) : (
              <Logo
                className={`header__logo ${
                  isIntroVideoScreen ? "header__logo--hidden" : ""
                }`}
              />
            )}
          </a>
          <div ref={menuIcon} className="header__icons">
            <Menu
              isNavOpen={isNavOpen}
              setIsNavOpen={setIsNavOpen}
              isInitScreen={isInitScreen}
              isIntroVideoScreen={isIntroVideoScreen}
              setIsModelLinkOn={setIsModelLinkOn}
            />
          </div>
        </div>
      </div>
    </header>
  );
}
