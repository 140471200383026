import { Html } from "@react-three/drei";
import sectionImageFlag1 from "../media/4-nations-flag-01.png";
import sectionImageFlag2 from "../media/4-nations-flag-02.png";
import sectionImageFlag3 from "../media/4-nations-flag-03.png";
import sectionImageFlag4 from "../media/4-nations-flag-04.png";
import sectionImageFlag5 from "../media/4-nations-flag-05.png";
import sectionImageFlag6 from "../media/4-nations-flag-06.png";
import sectionImageFlag7 from "../media/4-nations-flag-07.png";
import sectionImageFlag8 from "../media/4-nations-flag-08.png";

export default function CompassLinks({ isModelLinkOn }) {
  return (
    <>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[50.5, 466, 70]}
        rotation-x={-Math.PI * 0.1184}
        rotation-y={-Math.PI * 0.645}
        rotation-z={-Math.PI * 0.066}
      >
        <a
          href="/industry-community/industry-media/"
          className="model-link__link"
          link-pair="link1"
        >
          <img
            src={sectionImageFlag8}
            alt="Industry Media"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[85.5, 0.5, 654]}
        rotation-x={Math.PI * 0.561}
        rotation-y={-Math.PI * 0.315}
        rotation-z={Math.PI * 0.5}
      >
        <a
          href="/industry-community/industry-media/"
          className="model-link__link"
          link-pair="link1"
          style={{ marginLeft: "1px" }}
        >
          <img
            src={sectionImageFlag7}
            alt="Industry Media"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle-alt ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>

      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[50.5, 466, -70]}
        rotation-x={Math.PI * 0.1184}
        rotation-y={Math.PI * 0.645}
        rotation-z={-Math.PI * 0.066}
      >
        <a
          href="/industry-community/associations/"
          className="model-link__link"
          link-pair="link2"
        >
          <img
            src={sectionImageFlag1}
            alt="Associations"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[85.5, 0.5, -654]}
        rotation-x={-Math.PI * 0.561}
        rotation-y={Math.PI * 0.315}
        rotation-z={Math.PI * 0.5}
      >
        <a
          href="/industry-community/associations/"
          className="model-link__link"
          link-pair="link2"
          style={{ marginLeft: "1px" }}
        >
          <img
            src={sectionImageFlag2}
            alt="Associations"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle-alt ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>

      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[50.5, -466, 70]}
        rotation-x={Math.PI * 0.1184}
        rotation-y={(Math.PI / 2) * 0.7}
        rotation-z={-Math.PI * 0.066 + Math.PI}
      >
        <a
          href="/industry-community/universities/"
          className="model-link__link"
          link-pair="link3"
        >
          <img
            src={sectionImageFlag5}
            alt="University Partners"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[85.5, -0.5, 654]}
        rotation-x={-Math.PI * 0.561}
        rotation-y={Math.PI * 2 * 0.342}
        rotation-z={-Math.PI * 0.5}
      >
        <a
          href="/industry-community/universities/"
          className="model-link__link"
          link-pair="link3"
          style={{ marginLeft: "1px" }}
        >
          <img
            src={sectionImageFlag6}
            alt="University Partners"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle-alt ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>

      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[50.5, -466, -70]}
        rotation-x={-Math.PI * 0.1184}
        rotation-y={(-Math.PI / 2) * 0.7}
        rotation-z={-Math.PI * 0.066 + Math.PI}
      >
        <a
          href="/industry-community/support-organisations/"
          className="model-link__link"
          link-pair="link4"
        >
          <img
            src={sectionImageFlag4}
            alt="Support Organisations"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[85.5, -0.5, -654]}
        rotation-x={Math.PI * 0.561}
        rotation-y={-Math.PI * 2 * 0.342}
        rotation-z={-Math.PI * 0.5}
      >
        <a
          href="/industry-community/support-organisations/"
          className="model-link__link"
          link-pair="link4"
          style={{ marginLeft: "1px" }}
        >
          <img
            src={sectionImageFlag3}
            alt="Support Organisations"
            className="model-link__image model-link__image--compass"
          />
          <div
            className={`model-link__shape model-link__shape--triangle-alt ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html transform zIndexRange={[1, 0]} wrapperClass="model-link"></Html>
    </>
  );
}
