import { useRef, useEffect } from "react";
import gsap from "gsap";
import { ReactComponent as MenuHeart } from "../media/menu-heart.svg";
import { ReactComponent as MenuCompass } from "../media/menu-compass.svg";
import { ReactComponent as TwitterIcon } from "../media/twitter-icon.svg";
import { ReactComponent as FacebookIcon } from "../media/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../media/instagram-icon.svg";
import { ReactComponent as LinkedinIcon } from "../media/linkedin-icon.svg";
import { ReactComponent as YoutubeIcon } from "../media/youtube-icon.svg";

export default function Menu({
  isNavOpen,
  setIsNavOpen,
  isInitScreen,
  isIntroVideoScreen,
  setIsModelLinkOn,
}) {
  const bgOverlay = useRef();
  // Toggle top navigation
  const handleNavOpen = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  useEffect(() => {
    isNavOpen || isInitScreen || isIntroVideoScreen
      ? setIsModelLinkOn(false)
      : setIsModelLinkOn(true);
    isNavOpen ? bgOverlayIn() : bgOverlayOut();
  }, [isNavOpen]);

  const bgOverlayIn = () => {
    gsap.set(bgOverlay.current, {
      width: "100vw",
    });
    gsap.to(bgOverlay.current, {
      duration: 0.5,
      alpha: 0.8,
      zIndex: 5,
    });
  };

  const bgOverlayOut = () => {
    gsap.to(bgOverlay.current, {
      duration: 0.5,
      alpha: 0,
    });
    gsap.set(bgOverlay.current, {
      delay: 0.5,
      zIndex: -1,
      width: "0",
    });
  };

  return (
    <nav className="nav">
      <button
        className={`hamburger ${isNavOpen ? "container--hidden" : ""}`}
        aria-expanded={isNavOpen ? "true" : "false"}
        onClick={handleNavOpen}
      >
        <span className="hamburger__line hamburger__line--line1"></span>
        <span className="hamburger__line hamburger__line--line2"></span>
        <span className="hamburger__line hamburger__line--line3"></span>
      </button>
      <div
        className="nav__bg-overlay"
        ref={bgOverlay}
        onClick={handleNavOpen}
      ></div>
      <div className="nav__menu">
        <div className="menu-section menu-section--featured-link menu-section--events">
          <a href="/uk-events-industry/" className="menu-section__link">
            <MenuHeart className="menu-section__logo menu-section__logo--heart" />
            <h4 className="menu-section__title">UK Events Industry</h4>
          </a>
        </div>
        <div className="menu-section menu-section--featured-link menu-section--industry">
          <a href="/industry-community/" className="menu-section__link">
            <MenuCompass className="menu-section__logo menu-section__logo--compass" />
            <h4 className="menu-section__title">Industry Community</h4>
          </a>
        </div>
        <div className="menu-section menu-section--nav">
          <ul className="menu">
            <li className="menu__item">
              <a href="/explore/" className="menu__link">
                Explore
              </a>
            </li>
            <li className="menu__item">
              <a href="/home/" className="menu__link">
                Home
              </a>
            </li>
            <li className="menu__item">
              <a href="/about/" className="menu__link">
                About Us
              </a>
              <ul className="menu menu--submenu">
                <li className="menu__item">
                  <a href="/about/supporters/" className="menu__link">
                    Supporters
                  </a>
                </li>
                <li className="menu__item">
                  <a href="/about/4-nations/" className="menu__link">
                    4 nations
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu__item">
              <a href="/research/" className="menu__link">
                Research
              </a>
            </li>
            <li className="menu__item">
              <a href="/news/" className="menu__link">
                News
              </a>
            </li>
            <li className="menu__item">
              <a href="/events-calendar/" className="menu__link">
                Events Calendar
              </a>
            </li>
            <li className="menu__item">
              <a href="/online-communities/" className="menu__link">
                Online communities
              </a>
            </li>
            <li className="menu__item">
              <a href="/podcasts/" className="menu__link">
                Podcasts
              </a>
            </li>
            <li className="menu__item">
              <a href="/help-faq/" className="menu__link">
                Help / Faq
              </a>
            </li>
            <li className="menu__item">
              <a href="/contact/" className="menu__link">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div className="menu-section menu-section--social-media">
          <div className="social-media">
            <a
              href="https://twitter.com/power_of_events"
              target="_blank"
              rel="noreferrer"
              className="social-media__icon"
            >
              <TwitterIcon alt="Twitter logo" />
            </a>
            <a
              href="https://www.facebook.com/ThePowerofEvents"
              target="_blank"
              rel="noreferrer"
              className="social-media__icon"
            >
              <FacebookIcon alt="Facebook logo" />
            </a>
            <a
              href="https://www.instagram.com/_thepowerofevents/"
              target="_blank"
              rel="noreferrer"
              className="social-media__icon"
            >
              <InstagramIcon alt="Instagram logo" />
            </a>
            <a
              href="https://www.linkedin.com/company/the-power-of-events"
              target="_blank"
              rel="noreferrer"
              className="social-media__icon"
            >
              <LinkedinIcon alt="Linkedin logo" />
            </a>
            <a
              href="https://www.youtube.com/@thepowerofeventsuk"
              target="_blank"
              rel="noreferrer"
              className="social-media__icon"
            >
              <YoutubeIcon alt="Youtube logo" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
