import { Html } from "@react-three/drei";
import sectionSportingImg from "../media/sector-bespoke-sporting-events.png";
import sectionBusinesssImg from "../media/sector-business-brand-experiences.png";
import sectionExhibitionsImg from "../media/sector-exhibitions-congress.png";
import sectionOutdoorImg from "../media/sector-outdoor-events-festivals.png";
import sectionMusicImg from "../media/sector-music-live-performances.png";
import sectionPublicImg from "../media/sector-public-third-sector.png";
import sectionWeddingsImg from "../media/sector-weddings-private-events.png";

export default function HeartLinks({ isModelLinkOn }) {
  return (
    <>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={13.6}
        position={[-681, 277, -747]}
        rotation-x={-Math.PI * 0.75}
      >
        <a
          href="/uk-events-industry/bespoke-sport-events/"
          className="model-link__link"
        >
          <img
            src={sectionSportingImg}
            alt="Bespoke Sport Events"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--square ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={13.6}
        position={[681, 277, -747]}
        rotation-x={-Math.PI * 0.75}
      >
        <a
          href="/uk-events-industry/business-brand-experiences/"
          className="model-link__link"
        >
          <img
            src={sectionBusinesssImg}
            alt="Business + Brand Experiences"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--square ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={13.6}
        position={[-1020, 168, -165]}
        rotation-x={Math.PI * 0.75}
        rotation-y={-Math.PI * 0.75}
        rotation-z={-Math.PI * 0.75}
      >
        <a
          href="/uk-events-industry/exhibitions-congress/"
          className="model-link__link"
        >
          <img
            src={sectionExhibitionsImg}
            alt="Exhibitions + Congress"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--square ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={13.6}
        position={[-345, 165, -165]}
        rotation-x={Math.PI * 0.75}
        rotation-y={Math.PI * 0.75}
        rotation-z={Math.PI * 0.75}
      >
        <a
          href="/uk-events-industry/outdoor-events-festivals/"
          className="model-link__link"
        >
          <img
            src={sectionOutdoorImg}
            alt="Outdoor Events + Festivals"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--square ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={13.6}
        position={[345, 165, -165]}
        rotation-x={Math.PI * 0.75}
        rotation-y={-Math.PI * 0.75}
        rotation-z={-Math.PI * 0.75}
      >
        <a
          href="/uk-events-industry/music-live-performances/"
          className="model-link__link"
        >
          <img
            src={sectionMusicImg}
            alt="Music + Live Performances"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--square ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={13.6}
        position={[1020, 168, -165]}
        rotation-x={Math.PI * 0.75}
        rotation-y={Math.PI * 0.75}
        rotation-z={Math.PI * 0.75}
      >
        <a
          href="/uk-events-industry/public-third-sector/"
          className="model-link__link"
        >
          <img
            src={sectionPublicImg}
            alt="Public + Third Sector"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--square ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={13.6}
        position={[0, 75, 416]}
        rotation-x={-Math.PI * 0.75}
      >
        <a
          href="/uk-events-industry/weddings-private-events/"
          className="model-link__link"
        >
          <img
            src={sectionWeddingsImg}
            alt="Weddings + Private Events"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--square ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html transform zIndexRange={[1, 0]} wrapperClass="model-link"></Html>
    </>
  );
}
