export const recommendationsData = [
  {
    value: "AN EVENT PROFESSIONAL",
    pageBg: "/home-js/media/recommendations/pageBg2.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "ASSOCIATIONS",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-associations-a.png",
        url: "/industry-community/associations/",
      },
      {
        title: "EVENTS CALENDAR",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-events-calendar-b.png",
        url: "/events-calendar/",
      },
      {
        title: "ONLINE COMMUNITIES",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-online-communities-b.png",
        url: "/online-communities/",
      },
      {
        title: "PODCASTS",
        bgImage: "/home-js/media/recommendations/bg5.svg",
        icon: "/home-js/media/recommendations/icon-podcasts-c.png",
        url: "/podcasts/",
      },
      {
        title: "SUPPORT ORGANISATIONS",
        bgImage: "/home-js/media/recommendations/bg6.svg",
        icon: "/home-js/media/recommendations/icon-support-organisations-c.png",
        url: "/industry-community/support-organisations/",
      },
      {
        title: "RESEARCH",
        bgImage: "/home-js/media/recommendations/bg7.svg",
        icon: "/home-js/media/recommendations/icon-research-c.png",
        url: "/research/",
      },
    ],
  },
  {
    value: "NEW TO THE EVENTS INDUSTRY",
    pageBg: "/home-js/media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "UNIVERSITY COURSES",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-university-courses-a.png",
        url: "/industry-community/universities/",
      },
      {
        title: "INDUSTRY NEWS",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-industry-news-b.png",
        url: "/news/",
      },
      {
        title: "ONLINE COMMUNITIES",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-online-communities-b.png",
        url: "/online-communities/",
      },
      {
        title: "PODCASTS",
        bgImage: "/home-js/media/recommendations/bg5.svg",
        icon: "/home-js/media/recommendations/icon-podcasts-c.png",
        url: "/podcasts/",
      },
    ],
  },
  {
    value: "A GRADUATE",
    pageBg: "/home-js/media/recommendations/pageBg4.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "INDUSTRY NEWS",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-industry-news-a.png",
        url: "/news/",
      },
      {
        title: "EVENTS CALENDAR",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-events-calendar-b.png",
        url: "/events-calendar/",
      },
      {
        title: "ONLINE COMMUNITIES",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-online-communities-b.png",
        url: "/online-communities/",
      },
      {
        title: "PODCASTS",
        bgImage: "/home-js/media/recommendations/bg5.svg",
        icon: "/home-js/media/recommendations/icon-podcasts-c.png",
        url: "/podcasts/",
      },
    ],
  },
  {
    value: "LOOKING TO JOIN THE INDUSTRY",
    pageBg: "/home-js/media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "UNIVERSITY COURSES",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-university-courses-a.png",
        url: "/industry-community/universities/",
      },
      {
        title: "INDUSTRY NEWS",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-industry-news-b.png",
        url: "/news/",
      },
      {
        title: "ONLINE COMMUNITIES",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-online-communities-b.png",
        url: "/online-communities/",
      },
      {
        title: "PODCASTS",
        bgImage: "/home-js/media/recommendations/bg5.svg",
        icon: "/home-js/media/recommendations/icon-podcasts-c.png",
        url: "/podcasts/",
      },
    ],
  },
  {
    value: "A CLIENT, INVESTOR OR FUNDER",
    pageBg: "/home-js/media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "RESEARCH",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-research-a.png",
        url: "/research/",
      },
      {
        title: "INDUSTRY NEWS",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-industry-news-b.png",
        url: "/news/",
      },
      {
        title: "ASSOCIATIONS",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-associations-b.png",
        url: "/industry-community/associations/",
      },
    ],
  },
  {
    value: "FROM GOVERNMENT",
    pageBg: "/home-js/media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "RESEARCH",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-research-a.png",
        url: "/research/",
      },
      {
        title: "INDUSTRY NEWS",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-industry-news-b.png",
        url: "/news/",
      },
      {
        title: "ASSOCIATIONS",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-associations-b.png",
        url: "/industry-community/associations/",
      },
    ],
  },
  {
    value: "FROM NATIONAL PRESS",
    pageBg: "/home-js/media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "RESEARCH",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-research-a.png",
        url: "/research/",
      },
      {
        title: "ASSOCIATIONS",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-associations-b.png",
        url: "/industry-community/associations/",
      },
      {
        title: "INDUSTRY NEWS",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-industry-news-b.png",
        url: "/news/",
      },
      {
        title: "EVENTS CALENDAR",
        bgImage: "/home-js/media/recommendations/bg5.svg",
        icon: "/home-js/media/recommendations/icon-events-calendar-c.png",
        url: "/events-calendar/",
      },
    ],
  },
  {
    value: "JUST EXPLORING",
    pageBg: "/home-js/media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "",
    boxes: [
      {
        title: "SECTOR OVERVIEWS",
        bgImage: "/home-js/media/recommendations/bg1.svg",
        icon: "/home-js/media/recommendations/icon-sector-overviews-a.png",
        url: "/uk-events-industry/",
      },
      {
        title: "UNIVERSITY COURSES",
        bgImage: "/home-js/media/recommendations/bg2.svg",
        icon: "/home-js/media/recommendations/icon-university-courses-a.png",
        url: "/industry-community/universities/",
      },
      {
        title: "INDUSTRY NEWS",
        bgImage: "/home-js/media/recommendations/bg3.svg",
        icon: "/home-js/media/recommendations/icon-industry-news-b.png",
        url: "/news/",
      },
      {
        title: "ONLINE COMMUNITIES",
        bgImage: "/home-js/media/recommendations/bg4.svg",
        icon: "/home-js/media/recommendations/icon-online-communities-b.png",
        url: "/online-communities/",
      },
      {
        title: "PODCASTS",
        bgImage: "/home-js/media/recommendations/bg5.svg",
        icon: "/home-js/media/recommendations/icon-podcasts-c.png",
        url: "/podcasts/",
      },
    ],
  },
];
