import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useThree } from "@react-three/fiber";
import { Html, useTexture, useAspect, softShadows } from "@react-three/drei";
import gsap from "gsap";
import Heart from "./Heart";
import Compass from "./Compass";
import { ReactComponent as ArrowIcon } from "../media/icon-arrow.svg";
import useScreenOrientation from "react-hook-screen-orientation";
import { isMobile } from "react-device-detect";

softShadows({
  frustum: 4.1,
  size: 0.04,
  near: 9.5,
  samples: 12,
  rings: 11,
});

export default function Experience({
  canvasEl,
  isExplorePage,
  setIsExplorePage,
  handleVideoEnd,
  isPlaying,
  setIsPlaying,
  isStartExperience,
  setIsStartExperience,
  isMainScreen,
  isRecommendationsScreen,
  setIsRecommendationsScreen,
  isInitialLoadDone,
  setIsInitialLoadDone,
  isModelLinkOn,
  setIsCursorLabelVisible,
  setIsInitScreen,
}) {
  const { size } = useThree();
  const bgMesh = useRef();
  const bgImage = useTexture("/home-js/textures/main-bg.jpg");
  const location = useLocation();
  const navigate = useNavigate();
  const screenOrientation = useScreenOrientation();
  const [previousScreenOrientation, setPreviousScreenOrientation] =
    useState(null);

  const scale = useAspect(
    1920, // Pixel-width
    1080, // Pixel-height
    1 // Optional scaling factor
  );

  const toggleModel = useRef();

  const [isModelToggle, setModelToggle] = useState(false);

  useEffect(() => {
    if (
      screenOrientation !== previousScreenOrientation &&
      previousScreenOrientation !== null
    ) {
      if (
        location.pathname === "/explore" ||
        location.pathname === "/explore/"
      ) {
        window.location.reload();
      } else {
        navigate("/explore/");
      }
    }
    setPreviousScreenOrientation(screenOrientation);
  }, [screenOrientation]);

  useEffect(() => {
    if (location.pathname === "/explore" || location.pathname === "/explore/") {
      initExploreAnimation();
      setIsPlaying(true);
      setTimeout(initExplorePage, 100);
    }
  }, []);

  const initExploreAnimation = () => {
    const canvasTopParent = canvasEl.current.parentNode.parentNode;
    gsap.fromTo(
      canvasTopParent,
      {
        filter: "blur(100px)",
        alpha: 0,
      },
      {
        filter: "blur(0px)",
        alpha: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );
  };

  const initExplorePage = () => {
    setIsInitScreen(false);
    setIsExplorePage(true);
    handleVideoEnd(0);
  };

  const allowExploreBtnClick = () => {
    toggleModel.current.style.pointerEvents = "auto";
  };

  const handleToggleModel = () => {
    toggleModel.current.style.pointerEvents = "none";
    setModelToggle((prevState) => !prevState);
    setTimeout(allowExploreBtnClick, 1400);

    if (isMobile) {
      const modelLinks = document.querySelectorAll(".model-link__link");
      for (const modelLink of modelLinks) {
        modelLink.classList.remove("model-link__link--hovered");
        modelLink.classList.remove("clicked");
        setIsCursorLabelVisible(false);
      }
    }
  };

  return (
    <>
      <directionalLight
        castShadow={size.width > 960 ? true : false}
        position={[0, 1, 6]}
        intensity={0.25}
        shadow-camera-far={12}
        shadow-camera-top={1.2}
        shadow-camera-right={5.5}
        shadow-camera-bottom={-1.8}
        shadow-camera-left={-5.5}
      />
      <ambientLight intensity={0.5} />
      <Heart
        isPlaying={isPlaying}
        isStartExperience={isStartExperience}
        setIsStartExperience={setIsStartExperience}
        isMainScreen={isMainScreen}
        isRecommendationsScreen={isRecommendationsScreen}
        setIsRecommendationsScreen={setIsRecommendationsScreen}
        isInitialLoadDone={isInitialLoadDone}
        setIsInitialLoadDone={setIsInitialLoadDone}
        isModelLinkOn={isModelLinkOn}
        isModelToggle={isModelToggle}
        setModelToggle={setModelToggle}
        isExplorePage={isExplorePage}
      />
      <Compass
        isPlaying={isPlaying}
        isStartExperience={isStartExperience}
        setIsStartExperience={setIsStartExperience}
        isMainScreen={isMainScreen}
        isRecommendationsScreen={isRecommendationsScreen}
        isInitialLoadDone={isInitialLoadDone}
        setIsInitialLoadDone={setIsInitialLoadDone}
        isModelLinkOn={isModelLinkOn}
        isModelToggle={isModelToggle}
        isExplorePage={isExplorePage}
      />

      {size.width < 961 && (
        <Html center position={[0, -0.8, 1]} rotation-y={0}>
          <div
            className={`toggle-model ${
              !isStartExperience ? "toggle-model--hidden" : ""
            }`}
            ref={toggleModel}
            onClick={handleToggleModel}
          >
            <div
              className={`toggle-model__icon toggle-model__icon--left ${
                !isModelToggle ? "toggle-model__icon--hidden" : ""
              }`}
            >
              <ArrowIcon />
              <ArrowIcon />
            </div>
            <p className="toggle-model__label">{isModelToggle ? "2" : "1"}/2</p>
            <div
              className={`toggle-model__icon toggle-model__icon--right ${
                isModelToggle ? "toggle-model__icon--hidden" : ""
              }`}
            >
              <ArrowIcon />
              <ArrowIcon />
            </div>
          </div>
        </Html>
      )}

      <mesh ref={bgMesh} scale={scale} position={[0, 0, -2.5]} receiveShadow>
        <planeGeometry />
        <meshStandardMaterial map={bgImage} />
      </mesh>
    </>
  );
}
