import { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";
import gsap from "gsap";
import logo from "../media/PowerOfEventsLogoMix.png";

export default function Intro({
  setIsInitScreen,
  setIsIntroVideoScreen,
  isMuted,
  isPlaying,
  setIsPlaying,
  handleVideoEnd,
}) {
  const videoFile = useRef();
  const loadingButton = useRef();
  const videoUrl =
    isMobile || window.innerWidth <= 960
      ? "https://www.youtube.com/watch?v=rlYFRTgOEUs"
      : "https://www.youtube.com/watch?v=f-5F_OauRgg";

  const [isReady, setIsReady] = useState(false);
  const tlButton = gsap.timeline({ repeat: -1 });

  useEffect(() => {
    loadingButton.current.innerHTML = loadingButton.current.innerText.replace(
      /\w/g,
      "<span>$&</span>"
    );

    if (
      loadingButton.current.classList.contains("loading-screen__text--loading")
    ) {
      tlButton.from(
        ".loading-screen__text--loading span",
        {
          duration: 0.4,
          alpha: 0,
          x: 40,
          stagger: {
            each: 0.1,
          },
        },
        "+=0.5"
      );
      tlButton.to(
        ".loading-screen__text--loading span",
        {
          duration: 0.4,
          alpha: 0,
          x: -40,
          stagger: {
            each: 0.1,
          },
        },
        "+=0.8"
      );
    } else {
      tlButton.from(".loading-screen__text--play span", {
        duration: 1.2,
        alpha: 0.2,
      });
      tlButton.to(".loading-screen__text--play span", {
        duration: 0.3,
        alpha: 1,
      });
      tlButton.to(".loading-screen__text--play span", {
        duration: 1.2,
        alpha: 0.2,
      });
    }
  });

  const onProgress = (event) => {
    const videoDuration = videoFile.current.getDuration();
    event.playedSeconds >= videoDuration - 2 && handleVideoEnd();
  };

  let count = 0;
  const counter = () => {
    count = count + 1;
    setTimeout(counter, 1000);
    if (count > 5) {
      return setIsReady(true);
    }
  };
  counter();

  const onReady = () => {
    count > 5 && setIsReady(true);
  };

  const playVideo = () => {
    const triggerPlay = () => {
      setIsPlaying(true);
    };

    const initVideoScreen = () => {
      setIsInitScreen(false);
      setIsIntroVideoScreen(true);
    };

    if (isReady) {
      const tlBg = gsap.timeline({ repeat: 0 });

      tlButton.pause();
      tlBg.to(".loading-screen__text--play span", {
        alpha: 0,
        y: 40,
        stagger: {
          each: 0.1,
        },
        onComplete: triggerPlay,
      });
      gsap.to(".loading-screen__container", {
        filter: "blur(100px)",
        alpha: 0,
        duration: 1.5,
        ease: "power2.inOut",
        delay: 0.4,
      });
      gsap.to(".loading-screen", {
        right: "100%",
        duration: 1,
        delay: 1.5,
        ease: "power2.inOut",
      });

      setTimeout(initVideoScreen, 1500);
    }
  };

  return (
    <>
      <div className="loading-screen">
        <button
          className={`loading-screen__container ${
            isReady ? "loading-screen__container--link" : ""
          }`}
          onClick={playVideo}
        >
          <img className="loading-screen__logo" src={logo} alt="Logo" />
          <p
            ref={loadingButton}
            className={`loading-screen__text ${
              isReady
                ? "loading-screen__text--play"
                : "loading-screen__text--loading"
            }`}
          >
            {isReady ? "PLAY" : "LOADING"}
          </p>
        </button>
      </div>

      <div className="intro">
        <div className="intro__container">
          <ReactPlayer
            ref={videoFile}
            playing={isPlaying}
            muted={isMuted}
            onReady={onReady}
            onProgress={onProgress}
            controls={false}
            className="intro__video"
            url={videoUrl}
            config={{
              youtube: {
                playerVars: {
                  color: "white",
                  controls: 0,
                  showinfo: 0,
                  rel: 0,
                  modestbranding: 1,
                },
              },
            }}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </>
  );
}
