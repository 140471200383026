import { useState, useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { Float, useGLTF, Text } from "@react-three/drei";
import { ModelPresentationControls } from "./ModelPresentationControls";
import HeartLinks from "./HeartLinks";
import gsap from "gsap";

export default function Heart({
  isPlaying,
  isStartExperience,
  isRecommendationsScreen,
  isInitialLoadDone,
  isModelLinkOn,
  setModelToggle,
  isModelToggle,
  isExplorePage,
}) {
  const { size } = useThree();
  const { nodes, materials } = useGLTF("/home-js/models/heart/heart.glb");
  const heart = useRef();
  const heartGroup = useRef();
  const heartText = useRef();

  // Create variable that stores GSAP matchMedia for detect resolutions
  let mm = gsap.matchMedia();

  useEffect(() => {
    if (!isPlaying && !isInitialLoadDone) {
      heartInitAnimation1();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isStartExperience && !isInitialLoadDone) {
      heartInitAnimation2();
    }
  }, [isStartExperience]);

  useEffect(() => {
    mm.add("(min-width: 961px)", () => {
      if (isInitialLoadDone) {
        isRecommendationsScreen ? heartOutAnimation() : heartInAnimation();
      }
    });
    mm.add("(max-width: 960px)", () => {
      if (isInitialLoadDone && isStartExperience) {
        if (isRecommendationsScreen && !isModelToggle) {
          heartOutAnimation();
        }
        if (!isRecommendationsScreen && isStartExperience) {
          isModelToggle ? setModelToggle(false) : heartInAnimation();
        }
      }
    });
  }, [isRecommendationsScreen, isStartExperience]);

  useEffect(() => {
    mm.add("(max-width: 960px)", () => {
      isModelToggle ? heartOutAnimation() : heartInAnimation();
    });
  }, [isModelToggle]);

  const heartInitAnimation1 = () => {
    const heartRotation = heart.current.rotation;
    const heartGroupPosition = heartGroup.current.position;
    const heartTextCurrent = heartText.current;
    const heartTextPosition = heartText.current.position;
    const heartScale = heart.current.scale;

    mm.add("(min-width: 961px)", () => {
      gsap.set(heartGroupPosition, {
        x: 0,
        y: modelPosition.y + 0.1,
      });
      gsap.set(heartScale, {
        x: "-=" + 0.00006,
      });
      gsap.set(heartRotation, {
        x: Math.PI / 2 - 0.32,
      });
      gsap.set(heartTextPosition, {
        x: -12,
      });
    });

    mm.add("(max-width: 960px)", () => {
      gsap.set(heartScale, {
        x: "-=" + 0.00006,
      });
      gsap.set(heartRotation, {
        x: Math.PI / 2 - 0.32,
      });
      gsap.set(heartTextCurrent, {
        visible: false,
      });
      gsap.set(heartTextPosition, {
        x: -12,
      });
    });
  };

  const heartInitAnimation2 = () => {
    const heartRotation = heart.current.rotation;
    const heartGroupPosition = heartGroup.current.position;
    const heartTextCurrent = heartText.current;
    const heartTextPosition = heartText.current.position;
    const heartScale = heart.current.scale;

    if (isExplorePage && size.width <= 960) {
      gsap.set(heartTextPosition, {
        x: -12,
      });
    }
    gsap.to(heartGroupPosition, {
      x: modelPosition.x,
      y: modelPosition.y,
      duration: 2.5,
      ease: "power2.inOut",
    });
    gsap.to(heartScale, {
      x: modelSize > 0.0008 ? modelSize : 0.0008,
      duration: 1.2,
      delay: 0.2,
      ease: "power2.inOut",
    });
    gsap.to(heartRotation, {
      x: Math.PI / 2,
      z: 0.3,
      y: 0.1,
      duration: 0.8,
      delay: 0.3,
      ease: "power2.inOut",
    });
    gsap.to(heartRotation, {
      z: 0,
      y: 0,
      duration: 0.8,
      delay: 1.2,
      ease: "power2.inOut",
    });
    gsap.set(heartTextCurrent, {
      visible: true,
    });
    gsap.to(heartTextPosition, {
      x: textPosition.x,
      duration: 3,
      ease: "power2.inOut",
    });
  };

  const heartOutAnimation = () => {
    const heartCurrent = heart.current;
    const heartRotation = heart.current.rotation;
    const heartPosition = heart.current.position;
    const heartTextPosition = heartText.current.position;

    mm.add("(min-width: 961px)", () => {
      gsap.to(heartRotation, {
        z: 0.4,
        y: 0.2,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(heartPosition, {
        x: -6,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(heartTextPosition, {
        x: -12,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });

    mm.add("(max-width: 960px)", () => {
      gsap.to(heartRotation, {
        z: 0.4,
        y: 0.2,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(heartPosition, {
        x: -6,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.set(heartCurrent, {
        delay: 1.4,
        visible: false,
      });
      gsap.to(heartTextPosition, {
        x: -6,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });
  };

  const heartInAnimation = () => {
    const heartCurrent = heart.current;
    const heartRotation = heart.current.rotation;
    const heartPosition = heart.current.position;
    const heartTextPosition = heartText.current.position;
    mm.add("(min-width: 961px)", () => {
      gsap.to(heartRotation, {
        z: 0,
        y: 0,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(heartPosition, {
        x: 0,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(heartTextPosition, {
        x: 0,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });

    mm.add("(max-width: 960px)", () => {
      gsap.set(heartCurrent, {
        visible: true,
      });
      gsap.to(heartRotation, {
        z: 0,
        y: 0,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(heartPosition, {
        x: 0,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(heartTextPosition, {
        x: 0,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });
  };

  const [modelSize, setModelSize] = useState(0.00153);
  const [fontSize, setFontSize] = useState(0.25);

  const calculateSize = () => {
    if (size.width > 1365) {
      setModelSize(0.00153);
      setFontSize(0.25);
    } else {
      setModelSize((size.width / 10000) * 0.011);
      setFontSize(size.width / 10000 + 0.11);
    }
  };

  const [modelPosition, setModelPosition] = useState({
    x: -2.5,
    y: -0.2,
  });

  const [textPosition, setTextPosition] = useState({
    x: 0,
    y: -1.6,
  });

  const calculatePosition = () => {
    if (size.width > 1365) {
      setModelPosition({
        x: -2.5,
        y: -0.2,
      });
      setTextPosition({
        x: 0,
        y: -1.6,
      });
    } else if (size.width > 960 && size.width < 1365) {
      setModelPosition({
        x: -size.width / 1000 - 1,
        y: -0.2,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.2,
      });
    } else {
      setModelPosition({
        x: 0,
        y: 0.7,
      });
      setTextPosition({
        x: 0,
        y: -0.5,
      });
    }
  };

  useEffect(() => {
    calculateSize();
    calculatePosition();
  }, [size.width]);

  return (
    <group
      ref={heartGroup}
      position-y={modelPosition.y}
      position-x={modelPosition.x}
    >
      <Float speed={1.1} rotationIntensity={0.6}>
        <ModelPresentationControls
          polar={[-0.2, 0.15]}
          azimuth={[-0.2, 0.2]}
          config={{ mass: 4, tension: 75 }}
          snap={{ mass: 10, tension: 400 }}
          speed={1}
          cursor={false}
        >
          <mesh
            geometry={nodes.Logo_3D.geometry}
            material={materials.Logo_3D}
            //scale={0.00155}
            scale={modelSize > 0.0008 ? modelSize : 0.0008}
            rotation={[Math.PI / 2, 0, 0]}
            castShadow
            ref={heart}
          >
            <HeartLinks isModelLinkOn={isModelLinkOn} />
          </mesh>
        </ModelPresentationControls>
      </Float>
      <Text
        font="/fonts/work-sans-v18-latin-800.woff"
        color={"#000"}
        fontSize={fontSize > 0.19 ? fontSize : 0.19}
        fontWeight={800}
        textAlign={"center"}
        position={[0, textPosition.y < -1.15 ? textPosition.y : -1.1, 1]}
        rotation-y={0}
        ref={heartText}
      >
        UK EVENTS INDUSTRY
      </Text>
    </group>
  );
}

useGLTF.preload("/home-js/models/heart/heart.glb");
