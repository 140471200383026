import { useRef, useEffect } from "react";
import gsap from "gsap";
import Dropdown from "./Dropdown";
import { isIOS } from "react-device-detect";
import { ReactComponent as SoundOffIcon } from "../media/icon-mute.svg";
import { ReactComponent as SoundOnIcon } from "../media/icon-unmute.svg";
import { ReactComponent as SkipIcon } from "../media/icon-skip.svg";
import { ReactComponent as BackIcon } from "../media/icon-play.svg";
import { ReactComponent as HomeIcon } from "../media/icon-home.svg";
import { ReactComponent as HeartIcon } from "../media/heart-icon.svg";
import soundOnEffect from "../media/audio/sound-effect.mp3";

export default function Footer({
  isInitScreen,
  isIntroVideoScreen,
  setIsStartExperience,
  isMainScreen,
  setIsMainScreen,
  isInitialLoadDone,
  isSingleModelPage,
  isNavOpen,
  isRecommendationsScreen,
  isMuted,
  setIsMuted,
  handleVideoEnd,
  setIsRecommendationsScreen,
  setBoxData,
  setIsModelLinkOn,
  isExplorePage,
}) {
  const footerEl = useRef();
  const exploreBtn = useRef();
  const soundIcon = useRef();
  const skipIcon = useRef();
  const homeIcon = useRef();
  const backIcon = useRef();
  const selectEl = useRef();
  const btnAudio = new Audio(soundOnEffect);

  useEffect(() => {
    if (isInitScreen && !isSingleModelPage) {
      iconHideAnimation(soundIcon.current);
      iconHideAnimation(skipIcon.current);
      iconHideAnimation(backIcon.current);
      iconHideAnimation(selectEl.current);
      iconInAnimation(homeIcon.current);
    }
    if (isIntroVideoScreen) {
      footerInAnimation();
      iconOutAnimation(homeIcon.current);
      iconInAnimation(soundIcon.current);
      iconInAnimation(skipIcon.current);
    } else {
      iconOutAnimation(skipIcon.current);
      iconInAnimation(exploreBtn.current, "-50%", 200);
    }
    if (isMainScreen) {
      iconInAnimation(homeIcon.current);
      selectInAnimation();
    } else {
      selectOutAnimation();
    }
    if (isRecommendationsScreen) {
      iconOutAnimation(homeIcon.current);
      iconInAnimation(backIcon.current);
    } else {
      iconOutAnimation(backIcon.current);
    }
  }, [isIntroVideoScreen, isMainScreen, isSingleModelPage]);

  useEffect(() => {
    if (isInitialLoadDone) {
      selectInAnimation();
      if (isExplorePage) {
        iconInAnimation(soundIcon.current);
      }
    }
  }, [isInitialLoadDone]);

  useEffect(() => {
    if (isNavOpen) {
      gsap.set(footerEl.current, {
        zIndex: 999999999,
      });
    } else {
      gsap.set(footerEl.current, {
        delay: 1,
        zIndex: 9999999999,
      });
    }
  }, [isNavOpen]);

  const startExperience = () => {
    setIsStartExperience(true);
    iconOutAnimation(exploreBtn.current, "-50%", 200);
  };

  useEffect(() => {
    if (isExplorePage) {
      setTimeout(startExperience, 2000);
    }
  }, [isExplorePage]);

  const footerInAnimation = () => {
    gsap.from(soundIcon.current, {
      duration: 1,
      alpha: 0,
      y: 100,
      overwrite: "auto",
    });
    gsap.from(skipIcon.current, {
      duration: 1,
      alpha: 0,
      y: 100,
      overwrite: "auto",
    });
  };

  const selectInAnimation = () => {
    if (selectEl.current) {
      gsap.fromTo(
        selectEl.current,
        {
          alpha: 0,
          y: 100,
          overwrite: "auto",
        },
        {
          duration: 1,
          alpha: 1,
          y: 0,
          overwrite: "auto",
        }
      );
    }
  };

  const selectOutAnimation = () => {
    if (selectEl.current) {
      gsap.to(selectEl.current, {
        duration: 1,
        alpha: 0,
        y: 100,
        overwrite: "auto",
      });
    }
  };

  const iconInAnimation = (icon, transformX = 0, movement = 100) => {
    gsap.fromTo(
      icon,
      {
        alpha: 0,
        x: transformX,
        y: movement,
        overwrite: "auto",
      },
      {
        duration: 1,
        alpha: 1,
        x: transformX,
        y: 0,
        overwrite: "auto",
      }
    );
  };

  const iconOutAnimation = (icon, transformX = 0, movement = 100) => {
    gsap.to(icon, {
      duration: 1,
      alpha: 0,
      x: transformX,
      y: movement,
      overwrite: "auto",
    });
  };

  const iconHideAnimation = (icon, transformX = 0, movement = 100) => {
    gsap.set(icon, {
      alpha: 0,
      x: transformX,
      y: movement,
    });
  };

  // Check if "muted" is saved on local storage on first load
  useEffect(() => {
    if (localStorage.getItem("muted") && !isIOS) {
      setIsMuted(JSON.parse(localStorage.getItem("muted")));
    } else {
      localStorage.setItem("muted", "false");
    }
  }, []);

  // Handle mute/unmute button
  const handleMute = () => {
    setIsMuted((isMuted) => !isMuted);
    localStorage.setItem("muted", JSON.stringify(!isMuted));
    //!isMuted && btnAudio.play();
  };

  const handleSkip = (event) => {
    event.currentTarget.disabled = true;
    handleVideoEnd();
  };

  const handleBack = (event) => {
    event.currentTarget.disabled = true;
    setIsRecommendationsScreen(false);
    setIsMainScreen(true);
  };

  return (
    <footer className="footer" ref={footerEl}>
      <div className="container container--footer">
        <div className="footer__content">
          <button
            ref={soundIcon}
            className="footer__button footer__button--sound"
            aria-label={`${isMuted ? "Disable sounds" : "Enable sounds"}`}
            onClick={handleMute}
            rollover={`${isMuted ? "UNMUTE" : "MUTE"}`}
          >
            {isMuted ? <SoundOnIcon /> : <SoundOffIcon />}
          </button>

          <button
            ref={exploreBtn}
            className={`explore-btn ${
              isMainScreen && !isInitialLoadDone && !isExplorePage
                ? ""
                : "explore-btn--hidden"
            }`}
            onClick={startExperience}
          >
            Explore
            <div className="explore-btn__icon">
              <HeartIcon />
              <HeartIcon />
            </div>
          </button>

          {!isSingleModelPage && (
            <Dropdown
              selectEl={selectEl}
              isIntroVideoScreen={isIntroVideoScreen}
              setIsRecommendationsScreen={setIsRecommendationsScreen}
              setBoxData={setBoxData}
              setIsMainScreen={setIsMainScreen}
              isInitialLoadDone={isInitialLoadDone}
              setIsModelLinkOn={setIsModelLinkOn}
            />
          )}

          <div className="footer__buttons">
            <button
              ref={skipIcon}
              className="footer__button footer__button--control footer__button--skip"
              aria-label={"Skip video"}
              onClick={handleSkip}
              rollover={"SKIP"}
            >
              <SkipIcon />
            </button>
            <button
              ref={homeIcon}
              className="footer__button footer__button--control footer__button--home"
              aria-label={"Go to Homepage"}
            >
              <a href="/home/" rollover={"HOME"}>
                <HomeIcon />
              </a>
            </button>
            <button
              ref={backIcon}
              className="footer__button footer__button--control footer__button--back"
              aria-label={"Back to Intro Page"}
              onClick={handleBack}
              rollover={"BACK"}
            >
              <BackIcon />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
}
